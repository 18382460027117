import React from 'react';
import './header.scss';

import Car from '../../img/jpg/jeep.jpg';

export default function Header(props) {
  return (
    <header
        style={{
            backgroundImage: `url(${Car})`
        }}
    >
        <div className="title">
            <h1>{props.title}</h1>
        </div>
    </header>
  )
};