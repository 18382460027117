import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Header from '../scenes/Header/Header';
import Grid from '../scenes/Grid/Grid';
import Footer from '../scenes/Footer/Footer';

export default function Realisations() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar realisations='active' />
            <Header title='Réalisations' />
            <div className="space-80"></div>
            <Grid />
            <div className="space-80"></div>
            <Footer />
        </div>
    )
};