import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../img/logo/logo.svg';
import './navbar.scss';

export default function Navbar(props) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav>
            <Link to="/" className="logo-box">
                <img src={Logo} alt='Logo KPUB'/>
            </Link>

            <div className={`burger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span className="burger-bar"></span>
                <span className="burger-bar"></span>
                <span className="burger-bar"></span>
            </div>

            <div className={`menu ${isOpen ? 'open' : ''}`}>
                <Link className={props.accueil} to="/" onClick={toggleMenu}>Accueil</Link>
                <Link className={props.realisations} to="/realisations" onClick={toggleMenu}>Nos réalisations</Link>
                <Link className={props.contact} to="/contact" onClick={toggleMenu}>Nous contacter</Link>
            </div>
        </nav>
    )
};