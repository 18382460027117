import React from 'react';
import { auth } from "../../firebase";

import HeaderAdmin from '../../components/AdminHeader/AdminHeader';
import AdminFormRealisations from '../../components/AdminForm/AdminFormRealisations';
import AdminFormActualites from '../../components/AdminForm/AdminFormActualites';
import AdminListRealisations from '../../components/AdminList/AdminListRealisations';
import AdminListActualites from '../../components/AdminList/AdminListActualites';

import "./admin.scss";

export default function Admin() {

  return (
    <div className='admin'>
      <div className="background-fixed"></div>
      <div className="content">
        <HeaderAdmin />
        <div className="space-30"></div>
        <div className="admin-grid-container">
          <AdminFormRealisations />
          <AdminFormActualites />
        </div>
        <div className="space-30"></div>
        <div className="admin-column-container">
          <AdminListRealisations />
          <AdminListActualites />
        </div>

        <div className="space-30"></div>
        <div className='btn-logout' onClick={() => auth.signOut()}>Se déconnecter</div>
        <div className="space-30"></div>
      </div>
    </div>
  )
};