import React from 'react';
import './modal.scss';

export default function Modal({ image, onClose }) {

  const handleClick = (e) => {
    if (e.target.classList.contains('modal')) {
      onClose();
    }
  };

  return (
    <div className='modal' onClick={handleClick}>
      <div className="img-box">
        <img src={image} alt="" />
      </div>
    </div>
  )
};