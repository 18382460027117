import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

import Slider from '../../components/Slider/Slider';
import './actualites.scss';

export default function Actualites() {

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    getActualites()
  }, [])

  const getActualites = async () => {
    const actualitesCollection = collection(db, 'actualites');
    const actualitesQuery = query(actualitesCollection, orderBy('timestamp', 'desc'));
    const querySnapshot = await getDocs(actualitesQuery);

    const dataArray = [];
    querySnapshot.forEach((doc) => {
      dataArray.push({ ...doc.data() });
    })
    
    setSlides(dataArray);
  }

  const sliceSlides = slides.slice(0, 5);

  return (
    <section className="actualites">
      <h2>Nos actualités</h2>
      <div className="slides">
        <Slider slides={sliceSlides} />
      </div>
    </section>
  )
};