import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

import Logo from '../../img/logo/logo.svg';
import Wolf from '../../img/logo/wolf.png';
import ArrowUp from '../../img/svg/arrow-up.svg';

export default function Footer() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer>
      <Link to="/" onClick={scrollToTop} className="logo-box">
        <img src={Wolf} alt="" />
      </Link>
      <Link to="/" onClick={scrollToTop} className="logo-box">
        <img src={Logo} alt="" />
      </Link>
      <p>Copyright ©2023 KPUB - Tout Droits Réservés - <Link to="/legal">Mentions Légales</Link></p>
      <div onClick={scrollToTop} className="ancre-top">
        <img src={ArrowUp} alt="" />
      </div>
    </footer>
  )
};