import React from 'react';
import './contactinfo.scss';

export default function ContactInfo() {
  return (
    <div className='contact-info'>
        <div className="left">
            <h2>Secrétariat</h2>
            <p>09 83 72 56 13</p>
            <p>secretariat@kpub84.com</p>
        </div>
        <div className="right">
            <h2>Direction</h2>
            <p>06 59 92 55 38</p>
            <p>contact@kpub84.com</p>
        </div>
    </div>
  )
};