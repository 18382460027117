import React, { useEffect } from 'react';

import Navbar from '../components/Navbar/Navbar';
import Header from '../scenes/Header/Header';
import MentionsLegales from '../scenes/MentionsLegales/MentionsLegales';
import Footer from '../scenes/Footer/Footer';

export default function Legal() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Navbar />
            <Header title='Mentions Légales' />
            <div className="space-80"></div>
            <MentionsLegales />
            <div className="space-80"></div>
            <Footer />
        </div>
    )
};