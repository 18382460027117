import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

import Card from '../../components/Card/Card';
import './lastwork.scss';

export default function LastWork() {

  const [realisations, setRealisations] = useState([]);

  useEffect(() => {
    getRealisations()
  }, [])

  const getRealisations = async () => {
    const realisationsCollection = collection(db, 'realisations');
    const realisationsQuery = query(realisationsCollection, orderBy('timestamp', 'desc'));
    const querySnapshot = await getDocs(realisationsQuery);
    setRealisations(querySnapshot.docs.map((doc) => doc.data()));
  }

  const sliceThree = realisations.slice(0, 3);

  return (
    <section className="lastwork">
      <div className="header">
        <h2>Nos dernières réalisations</h2>
      </div>
      <div className="grid-container">
        {sliceThree.map((realisation, idx) => (
          <div key={idx} className="grid-item">
            <Card image={realisation.image} type={realisation.type} car={realisation.car} />
          </div>
        ))}
      </div>
    </section>
  )
};