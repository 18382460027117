import React from 'react';
import './mentionslegales.scss';

export default function MentionsLegales() {
    return (
        <section className=''>
            <div className="">
                <h2>Propriété Intellectuelle</h2>
                <p>Tout contenu publié et mis à disposition sur ce site est la propriété de KPUB et de ses créateurs. Cela comprend, mais n’est pas limité aux images, textes, logos, documents, fichiers téléchargables et tout ce qui contribue à la composition de ce site.</p>
                <p>SIRET 818 755 605 00032</p>
                <p>Adresse : 985 Av. Marcel Pagnol, 84110 Vaison-la-Romaine</p>
                <p>Email : contact@kpub84.com</p>
            </div>

            <div className="space"></div>

            <div className="">
                <h2>Développeur</h2>
                <p>Florian D'Amato</p>
                <p>Email : florian@damato.fr</p>
            </div>

            <div className="space"></div>

            <div className="">
                <h2>Hébergeur</h2>
                <p>Site hébergé par o2switch SAS</p>
                <p>SIRET 510 909 807 00032</p>
                <p>RCS Clermont Ferrand</p>
            </div>

            <div className="space"></div>

            <div className="">
                <h2>Données Personnelles</h2>
                <p>www.kpub84.com s’engage à ne divulguer sous aucun prétexte d’informations concernant les utilisateurs. Un formulaire est à votre disposition pour contacter www.kpub84.com.</p>
                <p>En règle générale et Conformément à la loi «informatique et libertés» du 6 janvier 1978 modifiée en 2004, l’utilisateur bénéficie d’un droit d’accès et de rectification aux informations le concernant, droit qu’il peut exercer à tout moment en adressant un mail à contact@kpub84.com, ou par courrier, à l’adresse suivante : 985 Av. Marcel Pagnol, 84110 Vaison-la-Romaine.</p>
            </div>

            <div className="space"></div>

            <div className="">
                <h2>Droit applicable et lois concernées</h2>
                <p>Soumis au droit français, le site web www.kpub84.com est règlementé par la loi n° 2004-2005 du 21 juin 2004 pour la confiance dans l’économie numérique, l’article L.335-2 du Code de la Propriété Intellectuelle et la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004.</p>
            </div>

            <div className="space"></div>

            <div className="">
                <h2>Cookies</h2>
                <p>A l’entrée du site, l’utilisateur est informé du stockage sur son système informatique de cookies destinés à améliorer la navigation. Si vous ne souhaitez pas que des cookies soit stockés sur votre ordinateur, mobile ou tablette, vous devez interrompre la navigation sur ce site à l’apparition de la première page. Concernant les partages de page sur les réseaux réseaux, dés que vous partagez une page ou cliquez sur « j’aime », des cookies peuvent être stockés par les sites concernés sur votre système. www.kpub84.com, n’est en aucun cas responsable des cookies liés au sites de réseaux sociaux.</p>
            </div>
        </section>
    )
};