import React from 'react';
import './button.scss';

import ArrowRight from '../../img/svg/arrow-right-2.svg';

export default function Button({ href, type, children }) {
  return (
    <a href={href}>
      <button className={type}>{children}<img src={ArrowRight} alt="" /></button>
    </a>
  )
};