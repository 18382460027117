import React from 'react';
import './quisommesnous.scss';

export default function QuiSommesNous() {
  return (
    <section className='qsn'>
        <div className="left">
            <div className="img-box"></div>
        </div>
        <div className="right">
            <h2>Qui sommes-nous ?</h2>
            <p>KPUB est une société présente sur le marché depuis 2016, entreprise spécialisée dans l’application adhésive sur tous les supports depuis de nombreuses années.</p>
            <p>Nos principaux services sont l’application de covering, la protection de carrosserie sur véhicule ainsi que la décoration intérieure et diverse.</p>
            <p>Notre équipe est composée d'experts de l’application adhésive, certifiés HEXIS, dans l’application de vitrophanie et décoration intérieure. La qualité de nos prestations et le professionnalisme de nos poseurs font l’identité de notre société.</p>
        </div>
    </section>
  )
};