import React, { useEffect } from 'react';
import Header from '../scenes/HeaderHome/HeaderHome';
import QuiSommesNous from '../scenes/QuiSommesNous/QuiSommesNous';
import Brands from '../scenes/Brands/Brands';
import Prestations from '../scenes/Prestations/Prestations';
import Actualites from '../scenes/Actualites/Actualites';
import LastWork from '../scenes/LastWork/LastWork';
import Footer from '../scenes/Footer/Footer';

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Header />
            <div className="space-80"></div>
            <QuiSommesNous />
            <div className="space-80"></div>
            <Brands />
            <div className="space-80"></div>
            <Prestations />
            <div className="space-80"></div>
            <Actualites />
            <div className="space-80"></div>
            <LastWork />
            <div className="space-80"></div>
            <Footer />
        </div>
    )
};