import React, { useState } from 'react';
import PlusBlue from '../../img/svg/plus-blue.svg';
import './card.scss';

import Modal from './Modal'

export default function Card(props) {

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

  return (
    <>
        <div className='card-work'>
            <div 
                className="img-box"
                style= {{
                    backgroundImage: `url(${props.image})`
                }}
            ></div>
            <h3>{props.type}<br />{props.car}</h3>
            <div onClick={() => setShowModal(true)} className="hover-filter">
                <div className="plus">
                    <img src={PlusBlue} alt="" />
                </div>
            </div>
        </div>
        { showModal ? <Modal image={props.image} onClose={handleCloseModal} /> : null }
    </>
  )
};