import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Logo from '../../img/logo/logo.svg';
import ArrowDown from '../../img/svg/arrow-down.svg';
import './headerhome.scss';

// import Trailer from '../../video/trailer.mp4';

export default function Header() {
    return (
        <header className='header-home'>
            {/* <video src={Trailer} autoPlay loops muted className="background-video"></video> */}
            <Navbar accueil='active' />
            <div className="title">
                <h1>Welcome to</h1>
                <div className="logo-box">
                    <img src={Logo} alt="KPUB" />
                </div>
                <div className="img-box">
                    <img src={ArrowDown} alt='' />
                </div>
            </div>
        </header>
    )
};