import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './styles/style.scss';

import Home from './pages/Home';
import Realisations from './pages/Realisations';
import Contact from './pages/Contact';
import Legal from './pages/Legal';
import Admin from './scenes/Admin';

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/realisations" element={<Realisations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </>
  )
};