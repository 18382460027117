import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

import './adminlist.scss';

export default function AdminListActualites() {

    const [actualites, setActualites] = useState([]);

    useEffect(() => {
        getActualites()
    }, [])

    const getActualites = async () => {
        const actualitesCollection = collection(db, 'actualites');
        const actualitesQuery = query(actualitesCollection, orderBy('timestamp', 'desc'));

        const unsubscribe = onSnapshot(actualitesQuery, (querySnapshot) => {
            const updatedActualites = querySnapshot.docs.map((doc) => doc.data());
            setActualites(updatedActualites);
        });

        return () => {
            unsubscribe();
        }
    }

    function formatDate(timestamp) {
        if (timestamp) {
            const date = timestamp.toDate();
            const jour = date.getDate();
            const mois = date.getMonth() + 1;
            const annee = date.getFullYear();
            return `${jour}/${mois}/${annee}`;
        } else {
            return "N/A";
        }
    }

    // const [editing, setEditing] = useState(false);
    // const [editedActualite, setEditedActualite] = useState(null)

    // const startEdit = (actualite) => {
    //     setEditing(true);
    //     setEditedActualite(actualite);
    // };

    return (
        <div className="edit-list">
            <div className="title">Toutes les actualités</div>
            <div className="lines">
                {actualites.map((actualite, idx) => (
                    <div className="line" key={idx}>
                        <div className="left">
                            <div className="img-box">
                                <img src={actualite.image} alt="" />
                            </div>
                            <div className="type-car">{actualite.title}</div>
                        </div>
                        <div className="right">
                            <div className="date">{formatDate(actualite.timestamp)}</div>
                            {/* <div className="btn-modify" onClick={startEdit()}>Modifier</div> */}
                            <div className="btn-modify">Modifier</div>
                            <div className="btn-delete">Supprimer</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};