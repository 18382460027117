import React, { useState } from 'react';
import './slider.scss';

import ArrowLeft from '../../img/svg/arrow-left.svg';
import ArrowRight from '../../img/svg/arrow-right.svg';

export default function Slider({slides}) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };
    
    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToSlide = slideIndex => {
        setCurrentIndex(slideIndex);
    };

    if (!slides || slides.length === 0) {
        return null;
    }

    return (
        <div>
            <div className='slider'>
                <div
                    className="left"
                    style= {{
                    backgroundImage: `url(${slides[currentIndex].image})`
                    }}
                >
                    <div className="arrow-left" onClick={goToPrevious}>
                    <img src={ArrowLeft} alt="" />
                    </div>
                    <div className="arrow-right" onClick={goToNext}>
                    <img src={ArrowRight} alt="" />
                    </div>
                </div>
                <div className="right">
                    <h3>{slides[currentIndex].title}</h3>
                    <p>{slides[currentIndex].description}</p>
                    {/* <Button type='secondary' href={slides[currentIndex].href}>Voir plus</Button> */}
                </div>
                </div>
                <div className="slider-bullets">
                {slides.map((slide, slideIndex) => (
                    <div key={slideIndex} onClick={() => goToSlide(slideIndex)} className={currentIndex === slideIndex ? 'bullet active' : 'bullet'}></div>
                ))}
            </div>
        </div>
    )
};