import React, { useState, useRef } from 'react';
import { storage, db } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import './adminform.scss';

export default function AdminFormActualites() {

    const [previewImage, setPreviewImage] = useState();
    const [textPhoto, setTextPhoto] = useState('photo');
    const [loading, setLoading] = useState(false)

    function handleImageUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
            setTextPhoto('')
        } else {
            setPreviewImage('');
        }
    }

    const formRefAddActualites = useRef();

    const submitActualites = (e) => {
        e.preventDefault();

        setLoading(true)

        const image = formRefAddActualites.current[0]?.files[0];
        const title = formRefAddActualites.current[1]?.value;
        const description = formRefAddActualites.current[2]?.value;

        const storageActualitesRef = ref(storage, `actualites/${image.name}`);

        uploadBytes(storageActualitesRef, image).then(
            (snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadUrl) => {
                    saveActualites({
                        image: downloadUrl,
                        title,
                        description,
                        timestamp: serverTimestamp()
                    })
                }, () => {
                    saveActualites({
                        image: null,
                        title,
                        description,
                        timestamp: serverTimestamp()
                    })
                })
            }, () => {
                saveActualites({
                    image: null,
                    title,
                    description,
                    timestamp: serverTimestamp()
                })
            }
        )
    }

    const saveActualites = async (actualite) => {
        try {
            await addDoc(collection(db, 'actualites'), actualite);
            formRefAddActualites.current.reset();
            setPreviewImage()
            setTextPhoto('photo')
            setLoading(false)
        } catch (error) {
            alert("Erreur lors de l'ajout de l'actualité")
        }
    }

    return (
        <div className="add-form">
        <div className="title">Ajouter une actualité</div>
        <form ref={formRefAddActualites} onSubmit={submitActualites}>
            <input type="file" id='fileActualite' onChange={handleImageUpload} required />
            <label
                htmlFor="fileActualite"
                style={{
                    backgroundImage: `url(${previewImage || null})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                {textPhoto}
            </label>
            <input type="text" placeholder='titre' required />
            <input type="text" placeholder='description' required />
            <button type="submit">Ajouter</button>
        </form>
        {loading && (
            <div className="popup">
                <div className="popup-content">
                    <div className="img-box">
                        <div className='loader-import'>
                            <div className="circle"></div>
                        </div>
                    </div>
                    <div className='title'>Ajout de l'actualité...</div>
                </div>
            </div>
        )}
    </div>
    )
};