import { signInWithGoogle } from "../../firebase";
import './login.scss';

const Login = (props) => {
    return (
        <div className="login">
            <div className="container">
                <div className="title">Tableau de bord</div>
                <div className="btn-signin-admin" onClick={signInWithGoogle}>Se connecter</div>
                {props.em && (
                    <div className="error-message">Identifiants invalides</div>
                )}
            </div>
        </div>
    )
}

export default Login;