import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Admin from "./Admin";
import Login from "../../components/Login/Login";

export default function Dashboard() {

  const [errorMessage, setErrorMessage] = useState(false);
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.email === "floriandamato.dev@gmail.com" || user.email === "contact@kpub84.com") {
          setUser(user);
          setErrorMessage(false)
        } else {
          auth.signOut();
          setUser(null);
          setErrorMessage(true)
        }
      } else {
        setUser(null);
      }
    });
  }, [auth]);

  return (
    <div>
      {user ? <Admin /> : <Login em={errorMessage} />}
    </div>
  )
};