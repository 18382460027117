import React from 'react';
import { auth } from '../../firebase';

import './adminheader.scss';
import Logo from '../../img/logo/logo.svg';

export default function AdminHeader() {

    const user = auth.currentUser;
    const email = user.email;

    return (
        <div className='admin-header'>
            <img className='logo' src={Logo} alt="" />
            <p className='user'>Connecté en tant que : <span>{email}</span></p>
        </div>
    )
};