import React, { useState, useRef } from 'react';
import { storage, db } from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import './adminform.scss';

export default function AdminFormRealisations() {

    const [previewImage, setPreviewImage] = useState();
    const [textPhoto, setTextPhoto] = useState('photo');
    const [loading, setLoading] = useState(false)

    function handleImageUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
            setTextPhoto('')
        } else {
            setPreviewImage('');
        }
    }

    const formRefAddRealisation = useRef();

    const submitRealisations = (e) => {
        e.preventDefault();

        setLoading(true)

        const image = formRefAddRealisation.current[0]?.files[0];
        const type = formRefAddRealisation.current[1]?.value;
        const car = formRefAddRealisation.current[2]?.value;

        const storageRealisationsRef = ref(storage, `realisations/${image.name}`);

        uploadBytes(storageRealisationsRef, image).then(
            (snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadUrl) => {
                    saveRealisations({
                        image: downloadUrl,
                        type,
                        car,
                        timestamp: serverTimestamp()
                    })
                }, () => {
                    saveRealisations({
                        image: null,
                        type,
                        car,
                        timestamp: serverTimestamp()
                    })
                })
            }, () => {
                saveRealisations({
                    image: null,
                    type,
                    car,
                    timestamp: serverTimestamp()
                })
            }
        )
    }

    const saveRealisations = async (realisation) => {
        try {
            await addDoc(collection(db, 'realisations'), realisation);
            formRefAddRealisation.current.reset();
            setPreviewImage()
            setTextPhoto('photo')
            setLoading(false)
        } catch (error) {
            alert("Erreur lors de l'ajout de la réalisation")
        }
    }

    return (
        <div className="add-form">
            <div className="title">Ajouter une réalisation</div>
            <form ref={formRefAddRealisation} onSubmit={submitRealisations}>
                <input type="file" id='fileRealisation' onChange={handleImageUpload} required />
                <label
                    htmlFor="fileRealisation"
                    style={{
                        backgroundImage: `url(${previewImage || null})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    {textPhoto}
                </label>
                <input type="text" placeholder='ligne 1' required />
                <input type="text" placeholder='ligne 2' required />
                <button type="submit">Ajouter</button>
            </form>
            {loading && (
                <div className="popup">
                    <div className="popup-content">
                        <div className="img-box">
                            <div className='loader-import'>
                                <div className="circle"></div>
                            </div>
                        </div>
                        <div className='title'>Ajout de la réalisation...</div>
                    </div>
                </div>
            )}
        </div>
    )
};