import React from 'react';
import './prestations.scss';

export default function Prestations() {
  return (
    <section className='prestations'>
        <div className="left">
            <h2>Nos prestations</h2>
            <p>Depuis toujours, KPUB allie passion et professionnalisme pour donner vie à des créations adhésives de haute qualité.</p>
            <p>Nous sommes en mesure de réaliser des coverings complets et de personnaliser comme vous le souhaitez votre véhicule ; que ce soit des bandes latérales, du déchromage ou encore pour apporter une touche de couleur à votre véhicule nous saurons répondre à votre demande.</p>
            <p>Pour toute demande de projet covering spécifique, nos graphistes sont là pour vous aider. De là, nous sommes également disponible pour un projet de création de charte graphique, logo et image de marque.</p>
            <p>Nous sommes également installateurs de films de protection carrosserie, ce film épais transparent, auto-régénérant à la pointe de la technologie protège la peinture de votre véhicule des dommages quotidiens, y compris des éraflures, rayures, éclats...</p>
        </div>
        <div className="right">
            <div className="img-box"></div>
        </div>
    </section>
  )
};