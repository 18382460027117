import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";

import './adminlist.scss';

export default function AdminListRealisations() {

    const [realisations, setRealisations] = useState([]);

    useEffect(() => {
        getRealisations()
    }, [])

    const getRealisations = async () => {
        const realisationsCollection = collection(db, 'realisations');
        const realisationsQuery = query(realisationsCollection, orderBy('timestamp', 'desc'));
        // const querySnapshot = await getDocs(realisationsQuery);
        // setRealisations(querySnapshot.docs.map((doc) => doc.data()));

        const unsubscribe = onSnapshot(realisationsQuery, (querySnapshot) => {
            const updatedRealisations = querySnapshot.docs.map((doc) => doc.data());
            setRealisations(updatedRealisations);
        });

        return () => {
            unsubscribe();
        }
    }

    function formatDate(timestamp) {
        if (timestamp) {
            const date = timestamp.toDate();
            const jour = date.getDate();
            const mois = date.getMonth() + 1;
            const annee = date.getFullYear();
            return `${jour}/${mois}/${annee}`;
        } else {
            return "N/A";
        }
    }

    return (
        <div className="edit-list">
            <div className="title">Toutes les réalisations</div>
            <div className="lines">
                {realisations.map((realisation, idx) => (
                    <div className="line" key={idx}>
                        <div className="left">
                            <div className="img-box">
                                <img src={realisation.image} alt="" />
                            </div>
                            <div className="type-car">{realisation.type} - {realisation.car}</div>
                        </div>
                        <div className="right">
                            <div className="date">{formatDate(realisation.timestamp)}</div>
                            <div className="btn-modify">Modifier</div>
                            <div className="btn-delete">Supprimer</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};