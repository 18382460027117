import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

import './grid.scss';
import Card from '../../components/Card/Card';
import Button from '../../components/Button/Button';

const Grid = () => {

    const [realisations, setRealisations] = useState([]);

    useEffect(() => {
        getRealisations()
    }, [])

    const getRealisations = async () => {
        const realisationsCollection = collection(db, 'realisations');
        const realisationsQuery = query(realisationsCollection, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(realisationsQuery);
        setRealisations(querySnapshot.docs.map((doc) => doc.data()));
    }

    const [numberOfElement, setNumberOfElement] = useState(9);
    const LoadMore = () => {
        setNumberOfElement(numberOfElement + 6)
    }
    const sliceData = realisations.slice(0, numberOfElement);

    return (
        <div className="grid">
            <div className="grid-container">
                {sliceData.map((realisation, idx) => (
                    <div key={idx} className="grid-item">
                        <Card image={realisation.image} type={realisation.type} car={realisation.car} />
                    </div>
                ))}
            </div>
            <div className="space-80"></div>
            <div onClick={() => LoadMore()} className="btn">
                <Button type='primary simple center'>Voir plus</Button>
            </div>
        </div>

    );
};

export default Grid;